:root {
  font-size: 16px;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Roboto';
  }
  
  body {
    background-color: #f4f6f8;
    height: 100%;
  }
  
  a {
    text-decoration: none;
  }
  
  #root {
    height: 100%;
  }

  *:focus {
    outline: none !important;
  }
  

  /* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey; 
  /*border-radius: 10px;*/
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(40, 40, 40, 0.7);; 
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /*background: rgba(40, 40, 40, 1);; */
}

